import "@fontsource/roboto";
import { useState, useEffect, useMemo, useCallback } from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { Provider } from "@project-serum/anchor";
// @ts-ignore
import Wallet from "@project-serum/sol-wallet-adapter";
import {
  Signer,
  ConfirmOptions,
  Connection,
  Transaction,
  TransactionSignature,
  PublicKey,
} from "@solana/web3.js";
import {
  TokenListContainer,
  TokenListProvider,
} from "@solana/spl-token-registry";
import Swap from "@project-serum/swap-ui";
import "./App.css";
import { ConnectionProvider, WalletProvider, useWallet, useConnection } from '@solana/wallet-adapter-react';
import {
  getLedgerWallet,
  getPhantomWallet,
  getCoin98Wallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from '@solana/wallet-adapter-wallets';
import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import {
  WalletModalProvider,
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import NewButton from '@mui/material/Button';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import DiscordIcon from '@mui/icons-material/Chat';

// App illustrating the use of the Swap component.
//
// One needs to just provide an Anchor `Provider` and a `TokenListContainer`
// to the `Swap` component, and then everything else is taken care of.
function App() {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={8000}>
      <AppSnackbar></AppSnackbar>
    </SnackbarProvider>
  );
}

function AppSnackbar() {

  const network = "https://solana-api.projectserum.com/";

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getCoin98Wallet(),
      getLedgerWallet(),
      getSolletExtensionWallet({ network: "https://solana-api.projectserum.com/" as WalletAdapterNetwork }),
      getSolletWallet({ network: "https://solana-api.projectserum.com/" as WalletAdapterNetwork }),
    ],
    []
  );

  const { enqueueSnackbar } = useSnackbar();
  const onError = useCallback(
    (error: WalletError) => {


      enqueueSnackbar(error.message ? `${error.name}: ${error.message}` : error.name, { variant: 'error' });
      console.error(error);
    },
    [enqueueSnackbar]
  );


  return (
    <ConnectionProvider endpoint={network}>
      <WalletProvider wallets={wallets} onError={onError} autoConnect>
        <WalletModalProvider featuredWallets={8}>
          <ButtonAppBar />
          <AppInner/>
          <SimpleBottomNavigation />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    background: "#11161d",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

function openLink(expression) {
  switch (expression) {
    case 0:
      window.open("https://twitter.com/SolbergToken", "_blank");
      break;
    case 1:
      window.open("https://t.me/solbergtokencommunity", "_blank");
      break;
    case 2:
      window.open("https://www.instagram.com/solbergtoken/", "_blank");
      break;
    case 3:
      window.open("https://discord.com/invite/2w7J25Xgce", "_blank");
      break;
    default:
      break;
  }
}


function SimpleBottomNavigation() {
  const [value, setValue] = React.useState(0);

  return (
    <Box position="fixed" sx={{ width: '100%', background: 'transparent' }}>
      <BottomNavigation sx={{ position: "fixed", bottom: "0", width: "100%", background: 'transparent', color: '#fff' }} value={value} onChange={(event, newValue) => {
        setValue(newValue);
        openLink(newValue);
      }}>
        <BottomNavigationAction icon={<TwitterIcon htmlColor="#fff" />} />
        <BottomNavigationAction icon={<TelegramIcon htmlColor="#fff" />} />
        <BottomNavigationAction icon={<InstagramIcon htmlColor="#fff" />} />
        <BottomNavigationAction icon={<DiscordIcon htmlColor="#fff" />} />
      </BottomNavigation>
    </Box>
  );
}
const commonStyles = {
  borderBottom: 1
};

function ButtonAppBar() {
  return (
    <Box position="fixed" sx={{ ...commonStyles, flexGrow: 1, borderColor: '#7634ac', borderWidth: 5, borderBottom: 1, borderRight: 0, borderLeft: 0, borderTop: 0 }}>
      <AppBar style={{ background: "transparent" }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <img src="https://solbergtoken.com/assets/img/logoswap.png" width="216" />
          </Typography>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <img src="https://solbergtoken.com/assets/img/logoswap2.png" height="49" />
          </Typography>

          <Typography variant="h6" component="div" className="AppBarMenu" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            Solberg Swap
          </Typography>

          <Typography variant="body1" component="div" sx={{
            typography: 'body1',
            '& > :not(style) + :not(style)': {
            }, flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: "center"
          }}>
            <a href="https://solbergtoken.com" className="links">Website</a>
            <a href="https://dex.solbergtoken.com" className="links">DEX</a>
            <a href="" className="links soon">Staking</a>
            <a href="https://docs.solbergtoken.com" className="links">Docs</a>
          </Typography>

          <WalletMultiButton style={{
            backgroundColor: "#1a2029", color: "#f9f9f9", borderStyle: "solid",
            borderImageSlice: 1,
            borderWidth: "1px",
            borderImageSource: "linear-gradient(135deg, #0079ae, #142b75, #920092, #e40833, #fb8852 )",
            borderRadius: "4px",
          }} />
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const pages = ['Products', 'Pricing', 'Blog'];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            <img src="https://solbergtoken.com/assets/img/logoswap.png" width="216" />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            <img src="https://solbergtoken.com/assets/img/logoswap2.png" height="49" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <NewButton
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </NewButton>
            ))}
          </Box>
          <WalletMultiButton style={{
            backgroundColor: "#1a2029", color: "#f9f9f9", borderStyle: "solid",
            borderImageSlice: 1,
            borderWidth: "1px",
            borderImageSource: "linear-gradient(135deg, #0079ae, #142b75, #920092, #e40833, #fb8852 )",
            borderRadius: "4px",
          }} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};


function AppInner() {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isConnected, setIsConnected] = useState(false);
  const [tokenList, setTokenList] = useState<TokenListContainer | null>(null);
  const opts: ConfirmOptions = {
    preflightCommitment: "recent"
  }
  const newWallet = useWallet();
  const { connection: newConnection } = useConnection();
  const provider = new NasProvider(newConnection, newWallet as AnchorWallet, opts, (tx, err) => {
    if (err) { enqueueSnackbar(`Error: ${err.toString()}`, { variant: `error`, }); }
    else {
      enqueueSnackbar("Transaction sent", {
        variant: "success",
        action: (
          <Button
            color="inherit"
            component="a"
            target="_blank"
            rel="noopener"
            href={`https://explorer.solana.com/tx/${tx}`}
          >
            View on Solana Explorer
          </Button>
        ),
      });
    }
  });
  // const network = "https://api.mainnet-beta.solana.com";
  // const connection = new Connection(network, opts.preflightCommitment);
  //   const newProvider = new NotifyingProvider(
  //     connection,
  //     newWallet.wallet ?newWallet.wallet:  new  Wallet("https://www.sollet.io",network),
  //     opts,
  //      (tx, err) => {
  //        if (err) {
  //        enqueueSnackbar(`Error: ${err.toString()}`, {
  //           variant: "error",
  //          });
  //       } else {
  //          enqueueSnackbar("Transaction sent", {
  //          variant: "success",
  //           action: (
  //           <Button
  //              color="inherit"
  //              component="a"
  //               target="_blank"
  //              rel="noopener"
  //               href={`https://explorer.solana.com/tx/${tx}`}
  //            >
  //               View on Solana Explorer
  //            </Button>
  //          ),
  //         });
  //       }
  //     }
  //   );
  // const [provider, wallet] = useMemo(() => {
  //   const opts: ConfirmOptions = {
  //     preflightCommitment: "recent",
  //     commitment: "recent",
  //   };
  //   const network = "https://api.mainnet-beta.solana.com";
  //   const wallet = useWallet().wallet;

  //   const connection = new Connection(network, opts.preflightCommitment);
  //   const provider = new NotifyingProvider(
  //     connection,
  //     wallet,
  //     opts,
  //     (tx, err) => {
  //       if (err) {
  //         enqueueSnackbar(`Error: ${err.toString()}`, {
  //           variant: "error",
  //         });
  //       } else {
  //         enqueueSnackbar("Transaction sent", {
  //           variant: "success",
  //           action: (
  //             <Button
  //               color="inherit"
  //               component="a"
  //               target="_blank"
  //               rel="noopener"
  //               href={`https://explorer.solana.com/tx/${tx}`}
  //             >
  //               View on Solana Explorer
  //             </Button>
  //           ),
  //         });
  //       }
  //     }
  //   );
  //   return [provider, wallet];
  // }, [enqueueSnackbar]);

  useEffect(() => {
    new TokenListProvider().resolve().then(setTokenList);
  }, [setTokenList]);

  // // Connect to the wallet.
  // useEffect(() => {
  //   wallet.on("connect", () => {
  //     enqueueSnackbar("Wallet connected", { variant: "success" });
  //     setIsConnected(true);
  //   });
  //   wallet.on("disconnect", () => {
  //     enqueueSnackbar("Wallet disconnected", { variant: "info" });
  //     setIsConnected(false);
  //   });
  // }, [wallet, enqueueSnackbar]);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={styles.root}
    >
      {tokenList && <Swap provider={provider} tokenList={tokenList} referral={new PublicKey('GVitrsSUFinuxRCJhTKuUGPRmiaSFQ6RewG5H9Zf1K2d') } />}
    </Grid>

  );
}


// Cast wallet to AnchorWallet in order to be compatible with Anchor's Provider class
interface AnchorWallet {
  signTransaction(tx: Transaction): Promise<Transaction>;
  signAllTransactions(txs: Transaction[]): Promise<Transaction[]>;
  publicKey: PublicKey;
}

class NasProvider extends Provider {

  private onTransaction: (
    tx: TransactionSignature | undefined,
    err?: Error
  ) => void;

  constructor(
    connection: Connection,
    wallet: AnchorWallet,
    opts: ConfirmOptions,
    onTransaction: (tx: TransactionSignature | undefined, err?: Error) => void
  ) {
    super(connection, wallet, opts);
    this.onTransaction = onTransaction;
  }


  async send(
    tx: Transaction,
    signers?: Array<Signer | undefined>,
    opts?: ConfirmOptions
  ): Promise<TransactionSignature> {
    try {
      const txSig = await super.send(tx, signers, opts);
      this.onTransaction(txSig);
      return txSig;
    } catch (err) {
      if (err instanceof Error || err === undefined) {
        this.onTransaction(undefined, err);
      }
      return "";
    }
  }

  async sendAll(
    txs: Array<{ tx: Transaction; signers: Array<Signer | undefined> }>,
    opts?: ConfirmOptions
  ): Promise<Array<TransactionSignature>> {
    try {
      const txSigs = await super.sendAll(txs, opts);
      txSigs.forEach((sig) => {
        this.onTransaction(sig);
      });
      return txSigs;
    } catch (err) {
      if (err instanceof Error || err === undefined) {
        this.onTransaction(undefined, err);
      }
      return [];
    }
  }

}


// Custom provider to display notifications whenever a transaction is sent.
//
// Note that this is an Anchor wallet/network provider--not a React provider,
// so all transactions will be flowing through here, which allows us to
// hook in to display all transactions sent from the `Swap` component
// as notifications in the parent app.
class NotifyingProvider extends Provider {
  // Function to call whenever the provider sends a transaction;
  private onTransaction: (
    tx: TransactionSignature | undefined,
    err?: Error
  ) => void;

  constructor(
    connection: Connection,
    wallet: Wallet,
    opts: ConfirmOptions,
    onTransaction: (tx: TransactionSignature | undefined, err?: Error) => void
  ) {
    const newWallet = wallet as AnchorWallet;
    super(connection, newWallet, opts);
    this.onTransaction = onTransaction;
  }

  async send(
    tx: Transaction,
    signers?: Array<Signer | undefined>,
    opts?: ConfirmOptions
  ): Promise<TransactionSignature> {
    try {
      const txSig = await super.send(tx, signers, opts);
      this.onTransaction(txSig);
      return txSig;
    } catch (err) {
      if (err instanceof Error || err === undefined) {
        this.onTransaction(undefined, err);
      }
      return "";
    }
  }

  async sendAll(
    txs: Array<{ tx: Transaction; signers: Array<Signer | undefined> }>,
    opts?: ConfirmOptions
  ): Promise<Array<TransactionSignature>> {
    try {
      const txSigs = await super.sendAll(txs, opts);
      txSigs.forEach((sig) => {
        this.onTransaction(sig);
      });
      return txSigs;
    } catch (err) {
      if (err instanceof Error || err === undefined) {
        this.onTransaction(undefined, err);
      }
      return [];
    }
  }
}

export default App;
